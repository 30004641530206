import { template as template_d22fce735a1d4bcd8b3cbc446b1e108b } from "@ember/template-compiler";
const FKLabel = template_d22fce735a1d4bcd8b3cbc446b1e108b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
