import { template as template_bc2ba2a0eae545bcae294ba1ecfcaaa9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bc2ba2a0eae545bcae294ba1ecfcaaa9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
